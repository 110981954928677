.footer-contain {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: aquamarine;
  width: 100vw;
}

.navbar-contain{
  text-align: center;
  padding: 1rem 0;
  background-color: white;
  width: 100vw;
}

.navbar-contain svg{
  width: 30vw;
  height: auto;
  text-align: center;
}

.hero-svg svg {
  width: 80vw;
  height: auto;
}

.hero-body {
  height: 100vh;
  position: relative;
}

.serviceSvg{
  text-align: center;
}

.hero-body::before {
  opacity: .5;
  background-image: url("https://images.pexels.com/photos/830891/pexels-photo-830891.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-color: #cccccc;
  background-size: cover;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-body {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddbd89 ;
}

.about-contain{
  display: flex;
  align-items: center;
  padding-bottom: 15vh;
  flex-direction: column;
  background-color: #223043 ;
}

.about-box {
  width: 80vw;
  margin-bottom: 2rem;
}

.about-media{
  height: 25vh;
}

.menu-options {
  display: flex;
  gap: 1rem;
  margin: 1rem 3rem;
  justify-content: center;
}

.menu-options a {

  text-decoration: none;
  color: black;
}

.menu-optionssssss a {
  text-decoration: none;
  color: black;
}

.menu-options a:hover {
  background-color: #7a8ba0;
  padding: .8rem;
  border-radius: 10px;
  font-weight: bold;
  color: white; 
}

.option-select {
  background-color: #8aaadd;
  border-radius: 10px;
  font-weight: bold;
  color: white !important;
  padding: .8rem;
}

.option-select a {
  color: white;
}

.cont-mapa{
  display: flex;
  justify-content: center;
  padding: 3rem;
  background-color: #223043;
}

.card-mapa {
  display: flex;
  justify-content: center;
}

.card-mapa img {
  width: 40vw;
}

.div-mapa {
  padding: 1rem;
  width: 40vw;
}

.input-mapa {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 2rem;
}

.input-mapa button {
  color: white;
  font-weight: bold;
}

.bttn-cooon {
  color: #fff;
  text-decoration: none;
}

.about-whor {
  display: flex;
  background-color: white;
  width: 80vw;
  padding: 1rem;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  margin: 3rem 1rem;
}

.about-whor-img {
  width: 50vw;
  border-radius: 2rem;
}

.section-footer-content {
  width: 15vw;
}

.section-footer-img img {
  width: 10vw;
}

.container-footer {
  background-color: #4E5661;
  color: white;
}

.section-footer {
  display: flex;
  justify-content: space-around;
  padding: 2rem 1rem 0 1rem;
}

.center-foo {
  text-align: center;
  padding: 1rem;
}

.section-footer h2 {
  font-family: 'Lora', serif;
}
.container-menu {
  display: flex;
  background-color: white;
  width: 100vw;
  justify-content: space-between;
  margin: auto;
  padding: 1rem 0;
}

.section-footer-content {
  width: 15vw;
}

.section-footer-img img {
  width: 10vw;
}

.menu-logo{
  margin: 0 3rem;
  align-items: center;
  display: flex;
}

.menu-logo img{
  width: 12vw;
}

.menu-options {
  display: flex;
  gap: 1rem;
  margin: 0 3rem;
}

.menu-options a {

  text-decoration: none;
  color: black;
}

.menu-optionssssss a {
  text-decoration: none;
  color: black;
}

.option-select a {
  color: white;
}

.container-banner {
  width: 100vw;
  height: 85vh;
  background-color: #4d1919;
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.banner3 {
  
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.banner-title {
  margin: 3rem;
  color: #4d1919;
  width: 40vw;
  opacity: 1;
  position: absolute;
}

.banner-title h1{
  font-size: 3rem;
  font-family: 'Lora', serif;
}

.banner-title p{
  font-size: 1.2rem;
}

.container-footer {
  background-color: #4E5661;
  color: white;
}

.section-footer {
  display: flex;
  justify-content: space-around;
  padding: 2rem 1rem 0 1rem;
}

.container-about {
  background-color: white;
  display: flex;
  align-items: center;
}

.title-container{
  background-color: #742526;
  font-size: 2rem;
  text-align: center;
  padding: 3rem;
  font-family: 'Lora', serif;
  color: white;
}

.sec-abooooout {
  padding: 3rem;
  width: 50vw;
  text-align: justify;
}

.sec-about {
  padding: 3rem;
}

.testimg {
  object-fit: contain !important;
}

.sec-aboutty {
  padding: 3rem 3rem 0 3rem;
}

.sec-about h2 {
  color: #223043;
  font-weight: bold;
  width: fit-content;
  border-bottom: solid 8px #742526;
  padding-bottom: .5rem;
  letter-spacing: .3rem;
}

.sec-aboutty h2 {
  color: #223043;
  font-weight: bold;
  width: fit-content;
  border-bottom: solid 8px #742526;
  padding-bottom: .5rem;
  letter-spacing: .3rem;
}

.img-about {
  padding: 3rem;
  width: 60vw;
}

.img-about img {
  width: 100%
}

.container-valores {
  padding-top: 3rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.container-about2 {
  background-color: white;
  padding: 3rem;
}

.container-a3 {
  background-color: white;
  padding: 3rem;
  display: flex;
  justify-content: center;
  gap: 5rem;
}

.center-about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.margin-p {
  padding: 1rem;
  border-bottom: none !important;
}

.center-about h2{
  color: white;
}

.img-aboutet img{
  width: 500px;
}

.img-aboutet{
  display: flex;
  justify-content: center;
  height: 40vh;
  background-size: contain;
}

.img-aboutetas{
  display: flex;
  justify-content: center;
  height: 40vh;
  background: url(https://images.unsplash.com/photo-1552879890-3a06dd3a06c2);
  background-size: cover;
}

.img-aboutetasa{
  display: flex;
  justify-content: center;
  height: 40vh;
  background: url(https://images.unsplash.com/photo-1541888915364-aaeed51d238b);
  background-size: cover;
  background-position-y: 25%;
}

.img-aboutet-2{
  display: flex;
  justify-content: center;
  height: 80vh;
  background-size: cover;
}

.img-aboutet-6{
  display: flex;
  justify-content: center;
  height: 40vh;
  background: url(https://images.unsplash.com/photo-1531834685032-c34bf0d84c77);
  background-size: cover;
}

.img-aboutet-9{
  display: flex;
  justify-content: center;
  height: 60vh;
  background: url(https://images.unsplash.com/photo-1523848309072-c199db53f137);
  background-size: cover;
  background-position-y: center;
}

.cont-mapa{
  display: flex;
  justify-content: center;
  padding: 3rem;
  background-color: #223043;
}

.card-mapa {
  display: flex;
  justify-content: center;
}

.card-mapa img {
  width: 40vw;
}

.div-mapa {
  padding: 1rem;
  width: 40vw;
}

.input-mapa {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 2rem;
}

.input-mapa button {
  color: white;
  font-weight: bold;
}

.services-img {
  height: 65vh;
  background-size: cover;
  opacity: .8;
  background-position-x: center;
}

.service-card {
  padding: 3rem;
  display: flex;
  justify-content: center;
  background-color: #4d1919;
}

.typo-service {
  font-family: 'Lora', serif;
}

.service-box{
  background-color: white;
}

.center-services {
  display: flex;
  justify-content: center;
}

.imagenes-servicesss {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 2rem;
  background-color: #4d1919;
}

.container-a7 {
  padding: 3rem;
}

.center-foo {
  text-align: center;
  padding: 1rem;
}

.section-footer h2 {
  font-family: 'Lora', serif;
}

.menu-menu {
  display: none;
}

.card-section-2 {
  margin-top: 50px;
  border-top: solid 10px #223043;
  padding-top: 50px;
}

.card-section-1 {
  border-bottom: solid 10px #223043;
  padding-bottom: 50px;
}

.center-footer-btn {
  display: flex;
  justify-content: center;
  padding: .5rem;
}

.center-footer-btn a {
  text-decoration: none;
}

.nolinkd {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: .5rem;
}

.nolinkd a{
  text-decoration: none;
  color: white;
}

.exp-contain {
  display: flex;
  padding-top: 3rem;
  justify-content: center;
  gap: 3rem;
}

.exp-contain section{
  display: flex;
  align-items: center;
}

.exp-contain h2{
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 2.2rem;
}

.divv-footer-content {
  max-width: 30vw;
}

.alpha_wrapper {
  position: relative;
  height: 80vh;
  display: flex;
}

.container-banner {
  float: left;
  background-color: #4d1919;
}

.bannn1{
  justify-content: end;
  align-items: center;
}

.bannn1 .banner-title{
  justify-content: end;
  align-items: center;
  color: #223043;
}

.banner1 {
  opacity: 0.5;
}

.bannn2 .banner-title{
  display: none;
}

.bannn3 .banner-title{
  display: none;
}

.video-cont{
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.video-ome {
  height: 60vh;
}

.banner-titleds {
  display:none;
}

.foot-just {
  text-align: justify;
}

@media (max-width: 700px) {
  .about-whor {
    flex-direction: column;
  }
  .about-whor-img {
    width: 80vw;
  }
  .container-menu {
    width: 100vw;
  }
  .menu-options {
    margin-top: 1rem;
  }
  .menu-menu {
    display: block;
    padding-right: 1rem;
  }
  .menu-logo img {
    width: 20vw;
  }
  .section-footer{
    flex-direction: column;
    align-items: baseline;
    padding: 2rem;
  }
  .section-footer-content {
    width: 100%;
  }
  .container-about{
    flex-direction: column;
  }
  .container-a3{
    flex-direction: column;
    padding: 2rem 0;
    align-items: center;
    gap: 2rem;
  }
  .sec-abooooout {
    width: 80vw;
  }
  .container-a3 .card-section{
    width: 80vw;
    height: auto;
  }
  .card-section-2 {
    margin-top: 0;
  }
  .img-aboutet {
    height: 20vh;
  }
  .container-valores{
    flex-direction: column;
    padding: 0;
    padding-top: 1rem;
    align-items: center;
  }
  .wap-a2 {
    padding: 0;
    padding-top: 1rem;
  }
  .imagenes-servicesss {
    flex-direction: column;
  }
  .card-mapa {
    flex-direction: column;
    align-items: center;
  }
  .div-mapa {
    width: 65vw;
  }
  .card-mapa img {
    width: 65vw;
  }
  .input-mapa {
    padding-right: 0;
  }
  .title-container{
  font-size: 1.2rem;
  }
  .alpha_wrapper {
    position: relative;
    height: 40vh;
    display: flex;
  }
  .container-banner {
    width: 100vw;
    height: 40vh;
    background-color: #4d1919;
    display: flex;
    align-items: center;
  }
  .banner-title {
    margin: 0 2rem;
    color: white;
    width: 80vw;
  }
  .banner-title h1{
    font-size: 2rem;
  }
  .section-footer-img {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
  .section-footer-img img {
    width: 20vw;
    padding: 1rem;
  }
  .ul-contin {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divv-footer-content {
    width: 100%;
    max-width: 100%;
  }
  .pcorreo {
    text-align: center;
  }
  .nolinkd {
    flex-direction: row;
    gap: 2rem;
    padding: 2rem;
  }
  .card-section-2 {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }
  .img-about {
    padding: 1rem .5rem;
    width: 90vw;
  }
  .card-section-1 {
    border-bottom:none;
    padding-bottom: 0;
  }
  .exp-contain {
    flex-direction: column;
  }
  .sec-about {
    padding: 1rem;
  }
  .img-aboutet-2{
    height: 20vh;
  }
  .video-ome {
    width: 95vw;
    height: 30vh;
  }
  .banner-title{
    display: none;
  }
  .banner-titleds {
    font-size: .8rem;
    width: 70vw;
    text-align: justify;
    margin: auto;
    padding-top: 1rem;
    display: block;
  }
  .banner-titleds h1{
    text-align: center;
  }
  .video-cont{
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  }
  .banner1 {
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .navbar-contain svg{
    width: 10vw;
  }
  .hero-svg svg {
    width: 40vw;
  }
  .about-box {
    width: 50vw;
  }
  .about-media{
    height: 35vh;
  }
  
}